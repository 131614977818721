<template>
  <div class="SmartSourcingWarp">
    <img
      class="SmartSourcing"
      alt="SmartSourcing"
      src="@/assets/SmartSourcing.png"
    />
    <div class="SmartSourcingBox">
      <div class="SmartSourcingTop">
        <!-- <span class="RefreshBtn">刷新采购清单</span> -->
        <span
          class="ExploreBtn"
          @click="dialogExploreVisible=true"
          >导入采购清单</span
        >
        <span class="DownloadListBtn"   @click="DownloadListFun()"
          >下载报货清单</span
        >
        <SearchDiolag  @DialogF="initData"></SearchDiolag>
      </div>
      <div class="SmartSourcingBottom">
        <p v-show="!ProductListFlag">
          点击【导入采购清单】，可导入采购计划
          <!-- 点击【刷新采购清单】，可刷新上次采购计划 -->
        </p>
        <div v-show="ProductListFlag">
          <div v-for="(item, index)  in ProductList" :key="index" class="productBox">
            <div class="ProHeader">
              <span v-if="item.dateSn!==''">
                上传信息：{{item.goodName}} / {{item.goodSpecification==""?'规格:无':"规格:"+item.goodSpecification+""}} / 价格:{{item.price|capitalize}} / 数量:{{item.goodQuantity=='-1'?'0':item.goodQuantity}}
              </span>
               <span v-else>
                新增信息：{{item.product.name}}
              </span>
              <span  v-if="item.goodName"  @click="MatchFun(item.id,item.goodName)">
                <strong class="el-icon-search"></strong> 匹配药品
              </span>
            </div>
            <div   class="ProMain">
                <div v-if="item.productId==-1" class="FailBox">
                  匹配失败，请点击匹配药品
                  <span class="delBtn" @click="DelFun(item.id)">删除</span>
                </div>
                <div  v-else class="WinBox">
                  <el-col :span="1">
                    <label>
                      <!-- :disabled="item.product.stockTotal===0" -->
                      <input
                        type="checkbox"
                        :checked="item.flag"
                         @change="GoodsChange(index)"
                      />
                    </label>
                  </el-col>
                  <el-col class="goodsBox" :span="23">
                    <div class="imgBox"  @click="jumpGoodsDetail(item)">
                       <img v-if="item.product.quantity<Number(item.product.minOrderNum)" class="soldOut" alt="补货中" src="@/assets/soldOut.png" />
                      <!-- <img  alt="" :src="item.product.albumPics.split(',')[0] | defaultImage"/> -->
                      <img  alt="" :src="item.product.pic | defaultImage"/> 
                    </div>
                    <div  class="desBox">
                         <el-col :span="4">
                          <div class="desTit">
                            <span>{{ item.product.name }}</span>
                          </div>
                          <div class="desMsg">
                            <span v-if="item.product.validDateFromat">效期优于:{{ item.product.validDateFromat }}</span>
                            <span v-else>效期优于:无</span>
                          </div>
                          <div class="desMsg">
                            <span v-if="item.product.specification">规格:{{ item.product.specification }}</span>
                             <span v-else>规格:无</span>
                          </div>
                        </el-col>
                        <el-col class="SpecBox" :span="2">
                          <span v-if="item.product.partSpecification">{{ item.product.partSpecification }}</span>
                          <span v-else>无</span>
                        </el-col>
                        <el-col class="SpecBox" :span="6">
                          <span v-if="item.product.manufacturerName">{{ item.product.manufacturerName }}</span>
                          <span v-else>无</span>
                        </el-col>
                        
                        <el-col class="SpecBox" :span="6">
                          <span v-if="item.product.approvalNo">{{ item.product.approvalNo }}</span>
                          <span v-else>无</span>
                        </el-col>
                        <el-col class="PriceBox" :span="3">
                          <span :class="item.dateSn===''?'NoGoodSPrice':''">{{item.product.price | capitalize}}</span>
                          <span class="UpBox" v-if="(Number(item.product.price) - Number(item.price))>=0 && item.dateSn!==''"><strong class="el-icon-top"></strong>{{(item.product.price-item.price).toFixed(2) | capitalize}}</span>
                          <span class="DownBox" v-if="(Number(item.product.price) - Number(item.price))<0 && item.dateSn!==''"><strong class="el-icon-bottom"></strong>{{(item.price-item.product.price).toFixed(2) | capitalize}}</span>
                        </el-col>
                        <el-col  :span="3">
                          <div class="wraper" >
                            <button type='button'  class="reduce text-center" @click="ReducerFun(index)">-</button>
                            <input
                              type="text"
                              v-model="item.quantity"
                              :max="item.product.stockNum"
                              :min="item.product.minOrderNum"
                              :step="item.product.minOrderNum"
                               @change="ChangValue(index)"
                              ref="input"/>
                            <button type='button'  class="increase text-center"  @click="AddFun(index)">+</button
                            >
                          </div>
                        </el-col>
                    </div>
                  </el-col>
                </div>
            </div>
          </div>
          <div class="productBottom">
            <el-col :span="12" class="text-left">
              <!--  @change="modifySelected()" -->
              <el-col :span="3">
                <label>
                  <input type="checkbox" :checked="selectedAll"   @change="AllSelected()" /><span
                    >全选</span
                  >
                </label>
              </el-col>
              <el-col :span="2" class="text-center pointerDiv"  @click.native="DelProFun()">删除</el-col>
            </el-col>
            <el-col :span="12" class="text-right">
              <el-col :span="19"
                ><span class="total">
                   {{ goodsPriceSum | capitalize }}</span></el-col
              >
              <div :span="5" class="closeAcount pointerDiv text-center" @click="AddCartFun()">
                加入购物车
              </div>
            </el-col>
          </div>
        </div>
      </div>
    </div>

    <!-- 上传文件弹窗 start-->
    <el-dialog
      class="ExploreDialog"
      title="智能采购导入"
      :visible.sync="dialogExploreVisible"
      :close-on-click-modal="false"
    >
      <el-upload
        class="uploadBox"
        ref="upload"
        :action="UploadUrl()"
        :before-upload="beforeUploadFile"
        :on-exceed="exceedFile"
        :on-change="fileChange"
        :on-success="handleSuccess"
        :on-remove="handleRemove"
        :limit="limitNum"
        accept="csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        :http-request="httpRequest"
      >
        <el-button slot="trigger" size="small" type="primary"
          ><i class="el-icon-upload"></i> 选取文件</el-button
        >
        <el-button style="margin-left: 10px;" size="small" type="success"
          @click="ExportDownload()">点击下载导入模板</el-button
        >
        <div slot="tip" class="el-upload__tip">
          1.仅支持xls,xlsx格式的文件，且大小不能超过10M；
        </div>
        <div slot="tip" class="el-upload__tip">
          2.为保证上传成功，请您勿对模板中的单元格进行修改，其中标*为必填项；
        </div>
        <div slot="tip" class="el-upload__tip">
          3.一次仅支持一个文件导入，如有多份文件请分开上传；
        </div>
        <div slot="tip" class="el-upload__tip">
          4.文件导入后，如购买数量小于最小起订量，则默认导入最小起订量；
        </div>
      </el-upload>
      <el-table class="tableBox" border  :data="tableData"  v-show="SubmitFlag">
        <el-table-column prop="*药品名称" label="*药品名称"></el-table-column>
        <el-table-column prop="*购买数量" label="*购买数量"></el-table-column>
        <el-table-column prop="*规格" label="*规格"></el-table-column>
        <el-table-column prop="*价格" label="*价格"></el-table-column>
        <el-table-column prop="*生产厂家" label="*生产厂家"></el-table-column>
        <el-table-column prop="国药准字" label="国药准字"></el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer" v-show="SubmitFlag">
        <!-- <el-button  @click="submitUpload">提交清单</el-button> -->
        <el-button @click="uploadFile">提交清单</el-button>
      </div>
    </el-dialog>
    <!-- 上传文件弹窗 end -->

<MatchDialog  :matchData="matchData" @DialogF="initData"></MatchDialog>

  </div>
</template>
<script>
const SearchDiolag = () => import("components/common/searchDiolag.vue");
const MatchDialog = () => import("components/common/matchDialog.vue");
import {
  importPurchase, //导入文件传给后台接口
  getProList, //文件导入后台返回数据接口
  UpdateProNum,// 修改导入文件商品数量接口
  DelExportProduct,// 删除导入商品接口
  ExportPurchase,// 下载报货清单接口 
  AddPurchaseSaveCart //采购单加入购物车
} from "api/order.js";
// import { getToken } from "utils/session.js";

import XLSX from "xlsx"; //引入xlsx
export default {
  name: "WTSDOWN",
  data() {
    return {
      dialogExploreVisible: false, //导入文件弹窗开关
      SubmitFlag: false, //上传按钮是否显示开关
      limitNum: 1, // 上传excell时，同时允许上传的最大数
      fileList: [], // excel文件列表
      tableData: [], //获取上传excel列表展示数据
      fileData: "", //file文件流
      ProductListFlag: false, //商品列表开关
      ProductList:'',//导入商品数据
      selectedAll: false,//全选按钮开关  是否全选 默认false
      goodsPriceSum:0,   //初始选中商品总价
      matchData:{},//匹配商品初始弹窗开关
    };
  },
  methods: {
    // 商品详情
    jumpGoodsDetail(item){
       this.$router.push({ name: "ProductDetail", query: { id: item.product.id,isRecentExpiration:item.product.isRecentExpiration } });
    },
    downloadExcel(res){
        var blob = new Blob([res.data], { type: 'application/vnd.ms-excel  application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }); // application/vnd.openxmlformats-officedocument.spreadsheetml.sheet这里表示xlsx类型
      var downloadElement = document.createElement('a');
        var href = window.URL.createObjectURL(blob);//创建下载的链接
        downloadElement.href = href;
        downloadElement.download = '报货清单（请勿更改表头！）.xlsx';//下载后文件名
        document.body.appendChild(downloadElement);
        downloadElement.click();//点击下载
        document.body.removeChild(downloadElement);//下载完成移除元素
        window.URL.revokeObjectURL(href);//释放掉blob对象 
  },
    // 下载报货清单
    DownloadListFun(){
       this.$confirm("下载报货清单?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
        // 下载报货清单
        ExportPurchase().then(res=>{
          if (res) {
              this.downloadExcel(res);
            } else {
              this.$message.error(res.data.message || '导出excel失败，请稍后再试');
            }
        }).catch((err) => {
          this.$message.error(err.message || '导出excel失败，请稍后再试');
        });
          // window.open(
          //   `/api/blade-order/purchase/export-purchase?Blade-Auth=${getToken()}`,
          //   "_blank"
          // );
        })
         .catch(() => {});
    },
    // 点击下载导入模板
    ExportDownload(){
      window.location.href="https://104704-news-3akx.oss-cn-zhangjiakou.aliyuncs.com/upload/采购单模板（请勿更改表头！）.xlsx"
    },
    // 匹配商品
    MatchFun(id,seachval){
        this.matchData={
            matchDialogFlag:true,
            matchId:id,
            matchVal:seachval
        }
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
      this.SubmitFlag = false;
      this.fileList = [];
      this.tableData = [];
      this.fileData = "";
    },
    // 上传文件之前的钩子, 参数为上传的文件,若返回 false 或者返回 Promise 且被 reject，则停止上传
    beforeUploadFile(file) {
      console.log("before upload");
      console.log(file);
      let size = file.size / 1024 / 1024;
      if (!/\.(xls|xlsx)$/.test(file.name.toLowerCase())) {
        this.$message.warning("只能上传后缀是.xlsx/.xls的文件");
        return false;
      }
      if (size > 10) {
        this.$message.warning("文件大小不得超过10M");
        return false;
      }
    },
    // 文件超出个数限制时的钩子
    exceedFile(files, fileList) {
      this.$message.warning(
        `只能选择 ${this.limitNum} 个文件，当前共选择了 ${files.length +
          fileList.length} 个`
      );
    },
    // 文件状态改变时的钩子
    fileChange(file) {
      console.log(file.raw);
      this.fileList = [];
      this.fileList.push(file.raw);
      console.log(this.fileList);
      this.fileData = file.raw;
    },
    // 文件上传成功时的钩子
    handleSuccess() {
      this.$message.success("文件上传成功");
    },
    // 文件上传失败时的钩子
    handleError() {
      this.$message.error("文件上传失败");
    },
    httpRequest(e) {
      let file = e.file; // 文件信息
      const fileReader = new FileReader();
      let this_ = this;
      fileReader.onload = (ev) => {
        try {
          const data = ev.target.result;
          const workbook = XLSX.read(data, {
            type: "binary", // 以字符编码的方式解析
          });
          console.log(workbook);
          const exlname = workbook.SheetNames[0]; // 取第一张表
          console.log(XLSX);
          let exl = XLSX.utils.sheet_to_json(workbook.Sheets[exlname]); // 生成json表格内容
          exl.map((item) => {
            let time = this_.getNextDate("1900-01-01", item.date - 2);
            return (item.date = time);
          });
          this.tableData = exl;
          console.log(this.tableData);

this.tableData.forEach(item=>{
  console.log(item)
})

          this.SubmitFlag = true;
        } catch (e) {
          console.log("出错了：：");
          return false;
        }
      };
      fileReader.readAsBinaryString(file);
    },
    getNextDate(date, day) {
      var dd = new Date(date);
      dd.setDate(dd.getDate() + day);
      var y = dd.getFullYear();
      var m =
        dd.getMonth() + 1 < 10 ? "0" + (dd.getMonth() + 1) : dd.getMonth() + 1;
      var d = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate();
      return y + "-" + m + "-" + d;
    },
    UploadUrl: function() {
      // 因为action参数是必填项，我们使用二次确认进行文件上传时，直接填上传文件的url会因为没有参数导致api报404，所以这里将action设置为一个返回为空的方法就行，避免抛错
      return "";
    },
    uploadFile() {
      console.log("bbbbb", this.fileData);
      let form = new FormData();
      form.append("file", this.fileData);
      importPurchase(form).then((data) => {
        if (data.data.code === 400) {
           this.$message({
              message: data.data.msg,
              type: "warning",
            });
        } else {
          this.$message({
            message: data.data.msg,
            type: "success",
          });
          // 关闭导入文件弹窗
          this.dialogExploreVisible = false;
          // 打开商品列表盒子
          this.ProductListFlag = true;
          this.initData();
        }
      });
    },
     initData() {
        //  获取后台返回商品数据
        getProList().then(data=>{
          console.log(data.data.data)
          if(data.data.code==200){
            //给每一条数据都加一个状态值

            if(data.data.data.length>0){
              data.data.data.forEach((item) => {
                item.flag = false;
                // 判断商品导入数是否最小起订量的倍数
                // if(item.quantity <item.product.minOrderNum){
                //   item.quantity=item.product.minOrderNum
                // }else{
                //   if(item.quantity%item.product.minOrderNum==0){
                //     item.quantity=Number(item.quantity)
                //   }else{
                //     item.quantity=Number(Number(item.goodQuantity)-Number(item.goodQuantity)%Number(item.product.minOrderNum));
                //   }
                // }
              });
              this.selectedAll=false
              this.goodsPriceSum=Number(0).toFixed(2)
              this.ProductList=data.data.data;
              // 打开商品列表盒子
              this.ProductListFlag = true;
            }else{
               this.ProductListFlag = false;
            }

          }else{
            this.ProductListFlag = false;
          }
        })
      },

       //导入商品列表页单个勾选商品
        GoodsChange(index) {
          this.ProductList[index].flag = !this.ProductList[index].flag;
          var bStop = true;
          for (var i = 0; i < this.ProductList.length; i++) {
            // 判断是否匹配商品   && this.ProductList[i].product.stockTotal!==0
            if(this.ProductList[i].productId!=-1 ){
              if (!this.ProductList[i].flag) {
                bStop = false;
                break;
              }
            }
          }
          this.selectedAll = bStop;
          this.goodsPriceSum=this.goodsPrice()
        },
        // 导入商品列表页全选商品
        AllSelected() {
          this.selectedAll = !this.selectedAll;
          this.ProductList.forEach((item,i) => {
            // 判断都为匹配商品      && this.ProductList[i].product.stockTotal!==0
            if(this.ProductList[i].productId!=-1){
              item.flag = this.selectedAll;
            }
          });
          this.goodsPriceSum=this.goodsPrice()
        },
        // 获取选中价格总价
        goodsPrice() {
          let goodsPriceSum = 0;
          if (this.ProductList) {
            for (var i = 0; i < this.ProductList.length; i++) {
              if(this.ProductList[i].productId!=-1){
                if (this.ProductList[i].flag) {
                    goodsPriceSum +=this.ProductList[i].quantity * this.ProductList[i].product.price * 100;
                  }
                }
              }
            goodsPriceSum = (goodsPriceSum/ 100).toFixed(2);
          }
          return goodsPriceSum;
        },

        // 修改商品数量
         //改变input
        ChangValue(index) {
          this.ProductList[index].quantity=this.ProductList[index].quantity.replace(/\D/g, '')
          if(Number(this.ProductList[index].product.stockTotal)==0){
            this.ProductList[index].quantity = Number(this.ProductList[index].product.minOrderNum);
            this.$message({
              message: "该商品暂无库存"
            });
          }else{
            if (this.ProductList[index].quantity > this.ProductList[index].product.stockTotal) {
              this.ProductList[index].quantity = Number(Number(this.ProductList[index].product.stockTotal)-Number(this.ProductList[index].product.stockTotal)%Number(this.ProductList[index].product.minOrderNum));
              this.$message({
                message: "不能超过最大购买数量",
              });
            } else if (this.ProductList[index].quantity <= this.ProductList[index].product.minOrderNum) {
              this.ProductList[index].quantity = this.ProductList[index].product.minOrderNum;
              this.$message({
                message: "最小购买数量为" + this.ProductList[index].product.minOrderNum + "",
                type: "warning",
              });
            } else {
              if(Number(this.ProductList[index].quantity)%Number(this.ProductList[index].product.minOrderNum)===0){
                this.ProductList[index].quantity = Number(this.ProductList[index].quantity);
              }else{
                this.ProductList[index].quantity =  Number(this.ProductList[index].quantity)-Number(this.ProductList[index].quantity)%Number(this.ProductList[index].product.minOrderNum);
              }
            }
          }
          let datas = {
            quantity: this.ProductList[index].quantity,
            id: this.ProductList[index].id,
          };
          UpdateProNum(datas).then(() => {
          });
        },
        // 减
          ReducerFun(index) {
            if (this.ProductList[index].quantity <= this.ProductList[index].product.minOrderNum) {
              this.ProductList[index].quantity = this.ProductList[index].product.minOrderNum;
              this.$message({
                message: "最小购买数量为" + this.ProductList[index].product.minOrderNum + "",
                type: "warning",
              });
            } else {
              this.ProductList[index].quantity =
              this.ProductList[index].quantity - this.ProductList[index].product.minOrderNum;
            }
            this.goodsPriceSum=this.goodsPrice()
            let datas = {
              quantity: this.ProductList[index].quantity,
              id: this.ProductList[index].id,
            };
            UpdateProNum(datas).then(() => {
            });
          },
          //加
        AddFun(index) {
          if(Number(this.ProductList[index].product.stockTotal)==0){
            this.ProductList[index].quantity = Number(this.ProductList[index].product.minOrderNum);
            this.$message({
              message: "该商品暂无库存",
            });
          }else{
            if (this.ProductList[index].quantity > this.ProductList[index].product.stockTotal) {
              this.ProductList[index].quantity = Number(Number(this.ProductList[index].product.stockTotal)-Number(this.ProductList[index].product.stockTotal)%Number(this.ProductList[index].product.minOrderNum));
              this.$message({
                message: "不能超过最大购买数量",
              });
            } else {
              this.ProductList[index].quantity += this.ProductList[index].product.minOrderNum;
              if (this.ProductList[index].quantity > this.ProductList[index].product.stockTotal) {
                if(Number(this.ProductList[index].quantity)%Number(this.ProductList[index].product.minOrderNum)===0){
                  this.ProductList[index].quantity -= this.ProductList[index].product.minOrderNum;
                }else{
                  this.ProductList[index].quantity = Number(Number(this.ProductList[index].product.stockTotal)-Number(this.ProductList[index].quantity)%Number(this.ProductList[index].product.minOrderNum));
                }
                this.$message({
                  message: "不能超过最大购买数量",
                });
              }
            }
          }
          this.goodsPriceSum=this.goodsPrice()
          let datas = {
            quantity: this.ProductList[index].quantity,
            id: this.ProductList[index].id,
          };
          UpdateProNum(datas).then(() => {
          });
        },

        // 获取选中数量
        typeQuantity(){
          let goodsQuantity = 0;
          if (this.ProductList) {
            for (var i = 0; i < this.ProductList.length; i++) {
              if(this.ProductList[i].productId!=-1){
                if (this.ProductList[i].flag) {
                   goodsQuantity += 1;
                  }
                }
              }
          }
          return goodsQuantity;
        },
        // 加入购物车
        PushFun(item,AddCartDate){
              AddCartDate.push({
                      isRecentExpiration:item.product.isRecentExpiration,
                      quantity: item.quantity,
                      productId: item.product.id,
                      goodNum:item.goodNum,
                    });
        },

        // 请求成功
        AddCartSuccess(){
            // 关闭loading
            this.$loading().close();
            // 提示信息
                this.$notify({
                  title: "加入购物车",
                  message: "成功",
                  type: "success",
                  duration: 1000,
                });

                 this.$router.push({ name: "Cart" });
                // let count=0
                // AddCartDate.forEach(item=>{
                //   count+=item.product.quantity
                // })
                // this.setCarGoodsNum(count);
        },
        // 请求失败
         AddCartError(datas){
            this.$loading().close();
            this.$notify({
                  title: "提示信息",
                  message: datas.data.msg,
                  type: "error",
                  duration: 1000,
                });
         },
        AddCartFun(){
          if(this.typeQuantity()<1){
            this.$message({
                message:'最少选择一个商品',
                type:"warning"
            })
          }else{
            const AddCartDate = [];
            this.ProductList.forEach((item) => {
              // 判断都为匹配商品 且 库存不为0   && item.product.stockTotal!==0
              (item.productId!=-1)&&item.flag&&this.PushFun(item,AddCartDate)
              // if(item.productId!=-1 && item.product.stockTotal!==0){
              //   if(item.flag){
              //       AddCartDate.push({
              //         isRecentExpiration:item.product.isRecentExpiration,
              //         quantity: item.product.quantity,
              //         productId: item.product.id,
              //         goodNum:item.goodNum,
              //       });
              //   }
              // }
            });

             // 显示loading
            this.$loading({ fullscreen: true ,background:'#ffffff45'})
            // 加入购物车接口
            AddPurchaseSaveCart(AddCartDate).then((data) => {
              // this.$router.push({ name: "Cart", query: { id: id } });
               (data.data.code == 200) && this.AddCartSuccess() ;
               (data.data.code !== 200) && this.AddCartError(data)
              
            });
          }
        },
        // 删除未匹配商品
        DelFun(id){
          let ids = { ids: id };
          this.RemoveFun(ids,"您确定要删除该导入商品记录吗？")
        },
        // 删除导入商品接口
        DelProFun(){
            if(this.typeQuantity()<1){
              this.$message({
                  message:'最少选择一个商品',
                  type:"warning"
              })
            }else{
              let moreIds = "";
              this.ProductList.forEach((item) => {
                // 判断都为匹配商品 且 库存不为0
                if(item.productId!=-1){
                  if(item.flag){
                      let id = item.id;
                      moreIds += id + ",";
                  }
                }
              });
              console.log(moreIds)
               let ids = { ids: moreIds };
               this.RemoveFun(ids,"您确定要删除这些商品吗？")
          }
        },

        // 删除接口函数
        RemoveFun(datas,msg){
            this.$confirm(msg, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              })
            .then(() => {
              DelExportProduct(datas).then(
                (data) => {
                if (data.data.code == 200) {
                    this.$message({
                      type: "success",
                      message: "删除成功!",
                    });
                     this.initData();
                  } else {
                    this.$message({
                      type: "warning",
                      message: "删除失败!",
                    });
                  }
                })
           })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
        }
  },
  created() {
    this.initData();
  },
   components: {
     SearchDiolag,
     MatchDialog
  },
};
</script>
<style lang="less" scoped>
.SmartSourcing {
  width: 100%;
}
.SmartSourcingBox {
  background: #fff;
  .SmartSourcingTop {
    padding: 20px 20px 0 20px;
    height: 44px;
    // .RefreshBtn {
    //   display: inline-block;
    //   font-size: 16px;
    //   border: 2px solid #ff3333;
    //   line-height: 40px;
    //   padding: 0 32px;
    //   color: #fff;
    //   font-weight: 600;
    //   background: rgba(255, 51, 51, 1);
    //   margin-right: 20px;
    // }
    .ExploreBtn {
      display: inline-block;
      font-size: 16px;
      border: 2px solid #ff3333;
      line-height: 40px;
      padding: 0 32px;
     color: #fff;
      font-weight: 600;
      background: rgba(255, 51, 51, 1);
      margin-right: 20px;
    }
    .DownloadListBtn {
      display: inline-block;
      font-size: 16px;
      border: 2px solid #ff3333;
      line-height: 40px;
      padding: 0 32px;
      color: rgba(255, 51, 51, 1);
      font-weight: 600;
      background: rgba(255, 51, 51, 0.08);
      margin-right: 20px;
    }
   
  }
  .SmartSourcingBottom {
    p {
      min-height: 300px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #fff;
      color: #cccccc;
      font-size: 31px;
    }
    .productBox {
      margin: 20px 20px 10px 20px;
      border:1px solid rgba(219, 219, 219, 1);
      .ProHeader{
        padding:0 20px;
        height: 40px;
        background: rgba(240, 240, 240, 1);
        color: rgba(51, 51, 51, 1);
        font-weight: 600;

        span:nth-child(1){
          font-size: 12px;
          line-height: 40px;
        }
         span:nth-child(2){
           float: right;
          font-size: 12px;
          color: #409fe0;
          padding: 4px 6px;
          margin-top: 7px;
          border: 1px solid #409fe0;
          strong{
            font-weight: 800;
          }
        }
      }
      .ProMain{
        text-align: left;
        .FailBox{
            padding:0 20px;
            color: rgba(102, 102, 102, 1);
            font-size: 20px;
            line-height: 96px;
            .delBtn{
              float: right;
              margin-left: 50px;
              font-size: 18px;
              font-weight: bold;
              margin-top: 30px;
              cursor: pointer;
              display: block;
              padding: 5px 10px;
              border: 2px solid #ccc;
              height: 20px;
              line-height: 20px;
              &:hover{
                color:#ff3333;
                 border: 1px solid #ff3333;
              }
            }
        }
        .WinBox{
          display: flex;
          align-items: center;
          position: relative;
          .operation{
              cursor: pointer;
              &:hover{
                color:#ff3333
              }
          }
          input{
          cursor: pointer;
          }
          & label {
            padding-left:20px;
          }
          .goodsBox{
            .imgBox {
              float: left;
              width: 72px;
              height: 72px;
              margin: 8px 8px;
              border: 1px solid #f6f6f6;
                .soldOut{
                display: block;
                width: 70px;
                height: 70px;
                z-index: 2;
                position:absolute;
               top: 10px;
                left: inherit;
              }
              img {
                display: inline-block;
                width: 70px;
                height: 70px;
                overflow: hidden;
              }
            }
          }
          .desBox {
            float: left;
            padding: 10px 0;
            width: calc(100% - 90px);
            .desTit {
              width: 100%;
              height:24px;
              line-height:24px;
            
              span{
                font-size: 14px;
                font-weight: bold;
                color: #333;
                 display:block;
                 float: left;
                white-space:nowrap;
                text-overflow:ellipsis;
                overflow:hidden;
                width: 120px;
              }
            }
            .desMsg {
              width: 100%;
              height: 22px;
              line-height:22px;
              font-size: 12px;
              color: #333;
            }
          }
          .SpecBox{
            line-height: 70px;
            font-size: 13px;
            color: #333333;
          }
          .PriceBox{
            span:nth-child(1){
              width: 100%;
              display: inline-block;
              margin-top:8px;
              line-height:30px;
              text-align: left;
              color: #333333;
              font-size: 16px;
              font-weight: bold;
               &.NoGoodSPrice{
                line-height:52px;
              }
            }
            span:nth-child(2){
              width: 100%;
              display: inline-block;
               font-size: 12px;
                line-height:30px;
              text-align: left;
              font-weight: bold;
              strong{
                font-weight: bold;
              }
            }
            .UpBox{
              color: #FF3333;
            }
             .DownBox{
              color: #17CF32;
            }
           
          }
           .wraper {
              width: 110px;
              height: 36px;
              background: #fff;
              display: flex;
              align-items: center;
              color: #666;
              margin-top: 17px;
              & > button {
                width: 16px;
                height: 36px;
                display: flex;
                justify-content: center;
                align-items: center;
                background: #f0f0f0;
                font-size: 15px;
                border: none;
                  cursor: pointer;
              }

              input {
                display: inline-block;
                width: 70px;
                height: 36px;
                background: #f0f0f0;
                text-align: center;
                margin-left: 4px;
                margin-right: 4px;
                border: none;
                font-size: 14px;
                cursor: pointer;

              }
            }
          }
        }
      }
    .productBottom {
      background: rgba(50, 47, 47, 1);
      display: flex;
      align-items: center;
      color: #fff;
      font-size: 13px;
      line-height: 18px;
      margin-top: 30px;
      margin-bottom: 50px;
      .pointerDiv {
        cursor: pointer;
      }
      input {
        cursor: pointer;
      }
      label {
        padding-left: 10px;
        display: flex;
        align-items: center;
        span {
          padding-left: 10px;
          cursor: pointer;
          &:hover {
            color: #ff3333;
          }
        }
      }
      .text-center {
        cursor: pointer;
        &:hover {
          color: #ff3333;
        }
      }
      .text-right {
        display: flex;
        align-items: center;
        .total {
          color: #fff;
          line-height: 25px;
          font-size: 18px;
          font-weight: bold;
          padding-right: 30px;
          text-align: right;
        }
       
        .closeAcount {
          width: 180px;
          height: 70px;
          background: #ff3333;
          color: #fff;
          font-size: 18px;
          line-height: 70px;
          font-weight: 600;
          cursor: pointer;
        }
      }
    }
  }
}
.ExploreDialog {
  /deep/.el-dialog__header {
    padding: 10px 20px;
    background: rgba(240, 240, 240, 1);
    color: rgba(51, 51, 51, 1);
    font-size: 14px;
    .el-dialog__headerbtn {
      top: 10px;
      font-size: 22px;
    }
  }
  /deep/.el-dialog__footer {
    .dialog-footer {
      .el-button {
        margin: 0 auto;
        font-size: 16px;
        border: 2px solid #ff3333;
        line-height: 40px;
        padding: 0 32px;
        color: #fff;
        font-weight: 600;
        background: #ff3333;
        border-radius: 0;
        display: block;
      }
    }
  }
  .tableBox {
    width: 100%;
    margin-top:20px;
    max-height: 300px;
    overflow-y: scroll;
    box-sizing: border-box;
    flex-shrink: 0;
    background: #f9f9f9;
    padding-bottom:1px;
  }
  ::-webkit-scrollbar {
    width: 2px;
  }

  /*定义滚动条轨道 内阴影+圆角*/
  ::-webkit-scrollbar-track {
    border-radius: 1px;
    background-color: rgba(0, 0, 0, 0.1);
  }

  /*定义滑块 内阴影+圆角*/
  ::-webkit-scrollbar-thumb {
    border-radius: 2px;
    box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
    background-color: rgba(0, 0, 0, 0.1);
  }
}
</style>
